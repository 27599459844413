import store from "@/store"


function quizId() {
    return store.state.quiz.data.id
}

function questionNumber() {
    return store.state.quiz.attempt.q
}

function lastAnswerNumber() {
    return store.state.quiz.attempt.answers[(store.state.quiz.attempt.q)]
}

function isAnswerCorrect(questionNumber, answerNumber) {    
    return store.state.quiz.data.question[questionNumber].question_id.answers[answerNumber].correct_answer
}

export default {
    quizId,
    questionNumber,
    lastAnswerNumber,
    isAnswerCorrect,
  };