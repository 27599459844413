<template>

  <div class="card">
    <div class="card-body text-center">
      
      <i class="bi bi-hand-thumbs-up text-primary" style="font-size: 5rem;"/>      
      <h2>{{ $t('testing.results.success.title') }}</h2>
      <p>{{ $t('testing.results.success.description') }}</p>

      <button type="button" class="btn btn-outline-primary px-5"            
            @click="shareDemo()">
      
            <i class="bi bi-share me-2" />
            <span>{{ $t('testing.results.success.share') }}</span>
      </button>
    </div>    
  </div>

</template>

<script>
import {  mapActions, mapGetters } from "vuex";

export default {
  name: "demo_success_result",
  data() {
    return {
    }
  },
  props: {
   
  },
  computed: {
    ...mapGetters({
			userHasPaidAccess: 'userHasPaidAccess'
		}),
  },

  methods: {
    ...mapActions(['doQuestionAnswer']),
    async shareDemo() {
      if (navigator.share) {
        try {
          const url = this.$router.resolve({ name: 'demo' }).fullPath+'?utm_source=share_button&utm_medium=referral&utm_campaign=success_demo_share'
          
          await navigator.share({
            title: this.$t('testing.results.success.share_title'),
            text: this.$t('testing.results.success.share_description'),
            url: url,
          });
          console.log('Контент успешно отправлен');
        } catch (error) {
          console.error('Ошибка при отправке', error);
        }
      } else {
        alert('Web Share API не поддерживается в этом браузере.');
      }
    },        
  },



}
</script>

<style scoped>

</style>