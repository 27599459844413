import currentQiuz from "@/utils/currentQiuz"

export default function questionAnswered() {
    
        
    try {
        window.ym( 98270400,'reachGoal','questionAnswered',
            {
                testVariant: currentQiuz.quizId(),
                question: {
                    question_number: currentQiuz.questionNumber()+1,
                    answer: {
                        answer_number: currentQiuz.lastAnswerNumber()+1,
                        correct: currentQiuz.isAnswerCorrect(currentQiuz.questionNumber(), currentQiuz.lastAnswerNumber())
                    }
                }                
            }
        );
    } catch (error) {
        console.error(error)
    }
    
}