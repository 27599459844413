
import store from "@/store"

export default function setUserParams() {
    
    const user = store.state.user  
       
    // фиксируем в базе дату последнего действия
    try {
        window.ym( 98270400,'userParams',
            {
                UserID: user.id,
                PaymentStatus: store.getters.userHasPaidAccess
            }
        );
    } catch (error) {
        console.error(error)
    }
    
}