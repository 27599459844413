<template>

  <div class="explanation_block">
    <div class="d-flex mb-2" :class="{ 'text-success': isCorrect, 'text-danger':!isCorrect}">
      <i class="bi bi-info-circle me-2"/>

      <span v-if="isCorrect">{{ $t('testing.answer.correct') }}</span>
      <span v-if="!isCorrect">{{ $t('testing.answer.wrong') }}</span>

    </div>
    
    <span class="explanation_description" v-html="description"/>
     
  </div>

</template>

<script>
export default {
  name: "quiz_answer_Explanation",
  data() {
    return {
    }
  },
  props: {
    description: {      
      required: true,
    },    
    isCorrect: { 
      type: Boolean,     
      required: true,
    },    
  },
  computed: {
    
    isWrongAnswer() {
      return !this.isCorrect 
    },
    isCorrectAnswer() {
      return this.isCorrect
    },
    
  },


}
</script>

<style>
.explanation_description{
  font-weight: lighter;
}
.explanation_block{
  
  padding: 0.8em;

  -webkit-box-shadow: 0px 0px 5px 0px rgba(36, 84, 55, 0.5) inset;
  -moz-box-shadow: 0px 0px 5px 0px rgba(36, 84, 55, 0.5) inset;
  box-shadow: 0px 0px 5px 0px rgba(36, 84, 55, 0.5) inset;
}
span.explanation_description > p:last-of-type{
    margin-bottom: 0px ;
}
</style>