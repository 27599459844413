
import currentQiuz from "@/utils/currentQiuz"
import store from "@/store"

export default function testResults() {
    
    try {
        window.ym( 98270400,'reachGoal','testResults',
            {
                testVariant: currentQiuz.quizId(),
                score_total: store.getters.score               
            }
        );
    } catch (error) {
        console.error(error)
    }
    
}