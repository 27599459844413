<template>
	<div v-if="selectOption && isLoaded" class="questionContainer">
		<div class="card text-center text-bg-secondary mb-2">
			<div class="card-body">
				<h3 class="card-title text-light mb-0">{{ $t('list.title') }}</h3>
			</div>
		</div>
		<div class="card">
			<ul class="list-group list-group-flush">
				<li v-for="variant in options" :key="variant.id"
					class="list-group-item list-group-item-action py-3 d-flex align-items-start"
					@click="quizListClic(variant)">

					<i class="bi bi-play-circle-fill me-3 text-primary" />
					<div>
						<p class="mb-1">{{ variant.name }}</p>
						<p class="questions-number mb-0">
							<i class="bi bi-list-check me-2" />
							<span>{{ $t('list.questionsNumber', {number: numberOfQuestions(variant)}) }}</span>
						</p>
					</div>

					<a type="button" class="btn btn-sm btn-outline-primary ms-auto" v-if="!userHasPaidAccess"
						:href="$t('testing.results.pricesUrl')">
						{{ $t('list.buy') }}
					</a>
				</li>
			</ul>

		</div>

		<router-link class="card mt-3" :to="{ name: 'demo' }">
			<div class="card-body d-flex align-items-start ">

				<i class="bi bi-play-circle-fill me-3 text-primary" />
				<div>
					<p class="mb-0 no-underline">{{ $t('list.demoTest.title') }}</p>
				</div>
			</div>
		</router-link>
	</div>
	<test v-if="!selectOption && isLoaded" :option="selected" />
</template>

<script>

import { mapGetters } from "vuex";
import test from './test.vue';
import quizService from '@/services/qiuz.js'
import { useRouter } from 'vue-router';

/** @todo: unload from db */
let option = 0;
export default {
	name: "quiz-list",
	components: {
		test
	},
	data() {
		return {
			options: option,
			selected: null,
			selectOption: true,
			isLoaded: false
		}
	},
	computed: {
		...mapGetters({
			userHasPaidAccess: 'userHasPaidAccess'
		}),
	},
	methods: {
		changeOption(n) {
			this.selected = n.toString();
			this.selectOption = false;
		},
		getOptions() {
			quizService
				.getOptionsData()
				.then(async (data) => {
					console.log(data);
					this.options = 4;
				})
				.catch((error) => {
					console.log(error)
					return 3;
				})
		},
		quizUrl(id) {
			const router = useRouter();
			return router.resolve({ name: 'quiz', params: { id } }).href;
		},
		numberOfQuestions(variant) {			
			return variant.question ? variant.question.length : 0
		},
		quizListClic(variant){
			if (this.userHasPaidAccess) {
				this.$router.push({ name: 'quiz', params: { id: variant.id } }) 
			}else{
				window.location.href = this.$t('testing.results.pricesUrl');
			}
		}
	},
	watch: {
		checkPromise() {
			if (this.drawer) {
				this.menuOpened = true;
				clearTimeout(this.menuOpenedTO);
			} else {
				this.menuOpenedTO = setTimeout(() => (this.menuOpened = false), 200);
			}
		},
	},
	mounted() {
		quizService
			.getOptionsData()
			.then(async (data) => {
				this.options = data.data.question.filter(r => Array.isArray(r.tags) && r.tags.includes('paid_test'));
				this.isLoaded = true;
			})
			.catch((error) => {
				console.log(error)
				return 3;
			})
	}
}
</script>

<style>

.questions-number{
	font-size: small;
	font-weight: lighter;
}
.no-underline {
  text-decoration: none;
}
</style>