<script>
import {defineComponent} from 'vue'
import {mapState} from "vuex";

export default defineComponent({
  name: "diagnose-payment",
  computed: {
    ...mapState({
      user: state => state.user,
    }),
    usr() {
      return localStorage.getItem('web_user_id')
    }
  },
})
</script>

<template>
  <div class="card" >
    <ul class="list-group list-group-flush">
      <li class="list-group-item pb-4">
        <p class="titleContainer">
          <span>UserID: {{usr}}</span>
          <br>
          <span>Fingerprint: {{user.id}}</span>
          <br>
          <span>Active: {{user.status}}</span>
        </p>
      </li>
    </ul>
  </div>
</template>

<style scoped>

</style>