<script>
import {defineComponent} from 'vue'
import {mapState} from "vuex";

export default defineComponent({
  name: "do-payment",
  computed: {
    ...mapState({
      fp: state => state.fp,
    }),
  },
  data() {
    return {
      countdown: null,
      timeLeft: 0,
      futureTime: new Date().getTime() + 60000,
      src: null,
    }
  },
  mounted() {
    const params = new URLSearchParams(window.location.search);
    params.set('usr', localStorage.getItem('web_user_id'));
    params.set('fp', this.fp.visitorId);
    this.src = 'https://n8n.kr3k.com/webhook/rt/make-payment?' + params.toString()
    this.startCountdown()
  },
  beforeUnmount() {
    clearInterval(this.countdown);
  },
  methods: {
    startCountdown() {
      this.timeLeft = 6;

      this.countdown = setInterval(() => {
        this.timeLeft -= 1;

        if (this.timeLeft <= 0) {
          clearInterval(this.countdown);
          this.timeLeft = '⏳';
        }
      }, 1000);
    },
    mergeQueryParams(url, newParams) {
      const urlObj = new URL(url);
      const params = new URLSearchParams(urlObj.href);
      console.log(params);

      // Обновляем параметры на основе переданного объекта
      for (const [key, value] of Object.entries(newParams)) {
        params.set(key, value);
      }

      // Заменяем параметры запроса в URL
      urlObj.search = params.toString();
      urlObj.host = 'n8n.kr3k.com'
      urlObj.pathname = 'webhook/rt/make-payment'
      return urlObj.toString();
    }
  }
})
</script>

<template>
  <div>
    <iframe id="roboframe" :src="src" style="position:fixed; top:0; left:0; bottom:0; right:0; width:100%; height:100%; border:none; margin:0; padding:0; overflow:hidden; z-index:999999;"></iframe>
    <div>
      <div class="d-flex justify-content-center">
        <div class="spinner-border" style="width: 4rem; height: 4rem;" role="status">
          <span class="visually-hidden"></span>
        </div>
      </div>
      <div class="text-center fs-1" style="margin-top: -60px">
        {{timeLeft}}
      </div>
      <div class="text-center">
        <br>
        {{ $t('system.paymentLoading') }}
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>