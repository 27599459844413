<template>

  <button class="btn d-flex justify-content-center " @click="selectOption(index)"    
    :class="{
      'is-selected': haveAnswer,
      'btn-danger': isWrongAnswer,
      'btn-primary': isCorrectAnswer,
      'btn-outline-primary': !haveAnswer,
      disabled: disabled && !haveAnswer
    }" >

    <i v-if="isCorrect && haveAnswer" class="bi bi-check-square-fill me-2 flex-grow-0" style="color: lightgreen;"/>    
    <i v-if="!isCorrect && haveAnswer" class="bi bi-dash-circle-fill me-2 flex-grow-0" style="color: bisque;"/>
    
    
    <span class="anstxt" v-html="response.text"/>
  </button>

</template>

<script>
import {  mapActions } from "vuex";

export default {
  name: "quiz_answer",
  data() {
    return {
    }
  },
  props: {
    response: {      
      required: true,
    },
    isCorrect: {      
      required: true,
    },
    haveAnswer: { 
      type: Boolean,     
      required: true,
    },
    index: {     
      required: true,
    },
    disabled: { 
      type: Boolean,     
      required: true,
    },
  },
  computed: {
    
    isWrongAnswer() {
      return !this.isCorrect && this.haveAnswer
    },
    isCorrectAnswer() {
      return this.isCorrect && this.haveAnswer
    },
    
  },

  methods: {
    ...mapActions(['doQuestionAnswer']),
    selectOption: function (answer) {
      if (!this.disabled)
        this.doQuestionAnswer({ answer })
    },
  },



}
</script>

<style>
span.anstxt > p:last-of-type{
    margin-bottom: 0px ;
}
</style>