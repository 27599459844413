// store.js
import {createStore} from 'vuex';
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import quizService from "@/services/qiuz";

import {setUserParams, questionAnswered} from '@/analitycs'

export default createStore({
    state: {
        fp: null,
        app: {
            inited: false,
        },
        user: {
            id: null,            
            status: false,
        },
        quiz: {
            data: {
                id: null,
                name: '',
                detailed_text:'',
                question: [],
            },
            attempt: {
                q: null,
                answers: []
            }
        },
    },
    getters: {
        score: (state) => {
            if (state.quiz) {
                const correct = state.quiz.data.question.map(q => q.question_id.answers.map(a => a.correct_answer).indexOf(true))
                return state.quiz.attempt.answers.map((a, i) => correct[i] === a).filter(a => a).length
            } else {
                return 0
            }
        },
        userHasPaidAccess: (state) => {            
            return Boolean(state.user.status)
        }
    },
    actions: {
        nextQuestion({state}) {
            if (state.quiz.attempt.q === null) {
                state.quiz.attempt.q = 0;
            } else {
                state.quiz.attempt.q++;
            }
        },
        async doQuestionAnswer({state}, options) {
            state.quiz.attempt.answers.push(options.answer)  
            
            questionAnswered()
        },
        restartQuiz({ dispatch }, options){
            console.log(options)
            dispatch('loadQuiz', {quiz_id: options.id})
        },
        async loadQuiz({state}, options) {
            quizService
                .getQuizData(options.quiz_id)
                .then(async (res) => {
                    state.quiz.attempt = {
                        q: null,
                        answers: []
                    }
                    state.quiz.data = res.data.question
                    state.quiz.data.id = options.quiz_id
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        async init({state}) {
            let web_user_id = localStorage.getItem('web_user_id')
            if (web_user_id === null) {
                web_user_id = quizService.generateRandomString(32)
                localStorage.setItem('web_user_id', web_user_id)
            }

            const fpPromise = await FingerprintJS.load();
            const fp = await fpPromise.get()
            state.fp = fp;
            await quizService
                .getPaymentStatus(`${fp.visitorId}_${web_user_id}`)
                .then(async (data) => {
                    state.user.status = data.data.status
                    state.user.id = fp.visitorId
                  
                    //state.user.status = true
                    //console.log(`${state.user.id} : ${state.user.status}`)

                    state.app.inited = true
                    // if (options.redirect) {
                    //     if (state.user.status) {
                    //         await router.push('/list');
                    //     } else {
                    //         await router.push('/demo');
                    //     }
                    // }

                    
                    setUserParams()
                })
                .catch((error) => {
                    console.log(error)
                })
        },
    },
});
