<template>
  <div class="card">
    <ul class="list-group list-group-flush">

      <li class="list-group-item explanation_description">
        <p class="pb-0" v-html="quiz.detailed_text" />
      </li>
      <li class="list-group-item list-group-item-secondary text-center py-3">
        <button class="btn btn-primary btn-lg" @click="nextQuestion">{{ $t('testing.startButton') }}</button>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "start_screen",
  data() {
    return {
    }
  },
  computed: {
    ...mapState({
      quiz: state => state.quiz.data,
      attempt: state => state.quiz.attempt,
    }),
  },
  methods: {
    ...mapActions(['nextQuestion']),
  },
}
</script>

<style scoped>
.start_screen_footer {
  padding-top: 0.5em;
}

</style>