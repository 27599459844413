<template>

  <div class="card">
    <div class="card-body text-center">
      <i class="bi bi-emoji-frown text-primary" style="font-size: 3rem;"/>
      <h6>{{ $t('testing.results.fail.title') }}</h6>
      <p>{{ $t('testing.results.fail.description') }}</p>

      <a type="button" class="btn btn-outline-primary px-5"            
            :href="$t('testing.results.pricesUrl')">
      
            <i class="bi bi-arrow-right pulsing-icon me-2" />
            <span>{{ $t('testing.results.fail.cta_button') }}</span>
      </a>
    </div>
  </div>  

</template>

<script>
import {  mapActions, mapGetters } from "vuex";

export default {
  name: "demo_fail_result",
  data() {
    return {
    }
  },
  props: {
   
  },
  computed: {
    ...mapGetters({
			userHasPaidAccess: 'userHasPaidAccess'
		}),   
    
  },

  methods: {
    ...mapActions(['doQuestionAnswer']),    
  },

}
</script>

<style scoped>

</style>