<template>

    <div class="testing-header d-flex align-items-center">
      <div class="progress w-100" role="progressbar" aria-label="testing-progress" :aria-valuenow="progressPercent" aria-valuemin="0" aria-valuemax="100">
        <div class="progress-bar" :style="'width: '+progressPercent+'%'"> <span class="testing-progres-description">{{progressValue}}</span></div>
      </div>

      <button type="button" class="ms-2 btn btn-primary" :class="{ disabled: !nextAllowed}" 
        style="--bs-btn-padding-y: .15rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .60rem;"
        role="navigation" v-on:click="nextQuestion();">
        {{ $t('testing.nextButton') }}
      </button>
    </div>
    
</template>

<script>
import {mapState, mapActions} from "vuex";

export default {
  name: "quiz_header",
  data() {
    return {      
    }
  },
  props: {
    nextAllowed: {
      type: Boolean,
      required: true,
    },
  },
  computed: {    
    ...mapState({
      quiz: state => state.quiz.data,
      attempt: state => state.quiz.attempt,
    }), 
    progressPercent() {      
      return  Math.trunc( ((this.attempt.q+1) / this.quiz.question.length) * 100)
    },
    progressValue() {      
      return  String(this.attempt.q +1 )+ ' / ' + String(this.quiz.question.length)
    },   
  },

  methods: {
    ...mapActions(['nextQuestion']),
  },
  
  

}
</script>

<style scoped>
.testing-header{
  position: fixed;
  left: 0; 
  top: 0;
  z-index: 10000;
  border-bottom: 1px solid darkgray;
  width: 100%;
  background-color: lightgray;
  padding: 0.5em 0.5em 0.5em 0.5em;
}
.testing-progres-description{
  font-size: .50rem
}
</style>